/* eslint-disable no-undef */
const PROD = {
	BASE_URL: 'https://billsload.enquirybot.com',
	WIDGET_PATH: 'https://launcher.enquirybot.com',
	INBOX_SOCKET_URL: 'https://billsinbox.enquirybot.com',
	PROTOCOL: 'https',
	MAILCHIMP_CLIENT_ID: '200434226269', // for Prod
	MAILCHIMP_CLIENT_SECRET_KEY: 'cc7e67d37539614959e95bcaa33eb8466c7de13afb303acff6'
};

const DEV = {
	BASE_URL: 'http://localhost:8000',
	WIDGET_PATH: 'http://localhost:9000',
	SOCKET_URL: 'http://localhost:4000',
	INBOX_SOCKET_URL: 'http://localhost:8455',
	PROTOCOL: 'http',
	MAILCHIMP_CLIENT_ID: '988959203957', // for Dev
	MAILCHIMP_CLIENT_SECRET_KEY: 'cc7e67d37539614959e95bcaa33eb8466c7de13afb303acff6',
	CLIENT_BASE_URL: 'http://127.0.0.1:3000'
};

const CI = {
	BASE_URL: 'https://codeci.enquirybot.com',
	WIDGET_PATH: '',
	SOCKET_URL: '',
	INBOX_SOCKET_URL: '',
	PROTOCOL: 'https',
	MAILCHIMP_CLIENT_ID: '988959203957', // for QA
	MAILCHIMP_CLIENT_SECRET_KEY: 'cc7e67d37539614959e95bcaa33eb8466c7de13afb303acff6'
};

const MAP = {
	production: PROD,
	development: DEV
};

let Config = PROD;

if (process.env.REACT_APP_ENV === 'ci_testing') Config = CI;
else Config = MAP[process.env.NODE_ENV];

export default Config;
